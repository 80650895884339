import styles from './style.module.scss';
import { UITypography } from '../typography/typography';
import { useState } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import { AnimatePresence, m } from 'framer-motion';
import classNames from 'classnames';

type UICollapseProps = {
  label: string;
  children: React.ReactNode | string;
  className?: string;
  style?: React.CSSProperties;
};

export const UICollapse = ({ children, label, className, style }: UICollapseProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const isChildenString = typeof children === 'string';

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={classNames(styles.root, className)} onClick={toggleCollapse} style={style}>
      <div className={styles.labelOverflow}>
        <div
          className={styles.labelContainer}
          style={{
            borderBottomLeftRadius: isOpen ? '0' : '20px',
            borderBottomRightRadius: isOpen ? '0' : '20px',
            paddingBottom: isOpen ? '8px' : '16px',
          }}
        >
          <UITypography variant="H2" component="p">
            {label}
          </UITypography>
          <m.div
            className={styles.iconContainer}
            initial={{ rotate: 45 }}
            animate={{ rotate: isOpen ? 90 : 45 }}
          >
            <CloseIcon />
          </m.div>
        </div>
      </div>

      <AnimatePresence>
        {isOpen && (
          <m.div
            className={styles.content}
            initial={{ y: '-100%' }}
            animate={{ y: 0 }}
            exit={{ y: '-100%' }}
            transition={{ duration: 0.2 }}
          >
            {isChildenString ? <UITypography>{children}</UITypography> : children}
          </m.div>
        )}
      </AnimatePresence>
    </div>
  );
};
