import { Variants } from 'framer-motion';

export const SELECT_ANIMATION_VARIANTS = {
  closed: {
    scale: 0,
    transition: {
      delay: 0.15,
    },
  },
  open: {
    scale: 1,
    transition: {
      type: 'spring',
      duration: 0.4,
    },
  },
} satisfies Variants;

export const SELECT_ARROW_VARIANTS = {
  down: { transform: 'rotate(0deg)' },
  up: {
    transform: 'rotate(-180deg)',
  },
} satisfies Variants;

export const LEFT_SWIPE_PARENT_VARIANTS = {
  hidden: {},
  visible: {
    transition: { staggerChildren: 0.05 },
  },
} satisfies Variants;

export const LEFT_SWIPE_CHILD_VARIANTS = {
  hidden: {
    x: '-25px',
    opacity: 0,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      stiffness: 250,
      damping: 15,
    },
  },
} satisfies Variants;
