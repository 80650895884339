const isScientificNotation = (value: string | number) => {
  const scientificNotationRegex = /^-?\d+(\.\d+)?[eE][-+]?\d+$/;
  return scientificNotationRegex.test(value.toString());
};

export const formatLargeNumber = (num: number | string, decimalLength = 4) => {
  if (typeof num !== 'string' && typeof num !== 'number') return '0';
  if (typeof num === 'number' && isNaN(num)) return '0';
  if (isScientificNotation(num)) return (+num).toFixed(decimalLength);

  const [integerPart, decimalPart] = num.toString().split('.');
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return decimalPart
    ? `${formattedInteger}.${decimalPart.slice(0, decimalLength)}`
    : formattedInteger;
};
