import styles from './style.module.scss';
import { CoinsCounter } from './coins-counter';
import { GameTimer } from './game-timer';
import { Swipe } from 'shared/ui/animations';

export const GamePanel = () => {
  return (
    <div className={styles.root}>
      <Swipe direction="vertical" from="top" style={{ width: '100%' }} delay={0.1}>
        <div className={styles.container}>
          <GameTimer />
          <CoinsCounter />
        </div>
      </Swipe>
    </div>
  );
};
