import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { UITypography } from 'shared/ui';
import { ReactComponent as TaskIcon } from 'assets/icons/task-square.svg';
import { Achievement, GetMoreTokensModal, Task, TaskModal } from 'components';
import { ReactComponent as MedalStarIcon } from 'assets/icons/medal-star.svg';
import { ReactComponent as MagicStarIcon } from 'assets/icons/magic-star.svg';
import { TaskEntity } from 'shared/types';
import { useCallback } from 'react';
import { Swipe } from 'shared/ui/animations';
import { m } from 'framer-motion';
import { LEFT_SWIPE_CHILD_VARIANTS, LEFT_SWIPE_PARENT_VARIANTS } from 'shared/constants';
import { useProgressStore } from 'shared/store';

export const ProgressModule = () => {
  const { t } = useTranslation();
  const tasks = useProgressStore((state) => state.tasks);
  const staticAchievements = useProgressStore((state) => state.staticAchievements);
  const dynamicAchievements = useProgressStore((state) => state.dynamicAchievements);
  const currentTask = useProgressStore((state) => state.currentTask);
  const setCurrentTask = useProgressStore((state) => state.setCurrentTask);

  const onTaskClick = useCallback((task: TaskEntity) => setCurrentTask(task), []);

  const onTaskModalClose = () => setCurrentTask(null);

  return (
    <div className={styles.root}>
      <m.div
        className={styles.scrollContainer}
        variants={LEFT_SWIPE_PARENT_VARIANTS}
        initial="hidden"
        animate="visible"
      >
        <Swipe variants={LEFT_SWIPE_CHILD_VARIANTS}>
          <UITypography component="h2" variant="H1">
            {t('progress.tasks')}
          </UITypography>

          <div className={styles.activeTasksList}>
            <UITypography
              iconLeft={<TaskIcon />}
            >{`${t('progress.active_tasks')} (${tasks.length})`}</UITypography>

            {tasks.map((task) => (
              <Task key={task.id} task={task} onClick={onTaskClick} />
            ))}
          </div>
        </Swipe>
        <Swipe variants={LEFT_SWIPE_CHILD_VARIANTS}>
          <UITypography component="h2" variant="H1">
            {t('progress.achievements')}
          </UITypography>

          <div className={styles.staticAchievementsList}>
            <UITypography
              iconLeft={<MedalStarIcon />}
            >{`${t('progress.static_achievements')} (${staticAchievements.length})`}</UITypography>
            {staticAchievements.map((staticAchievement) => (
              <Achievement key={staticAchievement.title} {...staticAchievement} />
            ))}
          </div>

          <div className={styles.staticAchievementsList}>
            <UITypography
              iconLeft={<MagicStarIcon />}
            >{`${t('progress.dynamic_achievements')} (${dynamicAchievements.length})`}</UITypography>
            {dynamicAchievements.map((dynamicAchievement) => (
              <Achievement key={dynamicAchievement.title} {...dynamicAchievement} />
            ))}
          </div>
        </Swipe>
      </m.div>

      <GetMoreTokensModal />

      {currentTask && (
        <TaskModal visible={!!currentTask} onClose={onTaskModalClose} task={currentTask} />
      )}
    </div>
  );
};
