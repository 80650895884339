import { useTranslation } from 'react-i18next';
import { TOKEN_SHORTCUT } from 'shared/constants';
import { UIButton, UIModal, UIModalProps, UINumberInput, UITypography } from 'shared/ui';
import styles from './style.module.scss';
import { useRef, useCallback, useState } from 'react';

type SendToBakeryModalProps = UIModalProps & {
  onComplete: () => void;
  value: string;
  onChange: (value: string) => void;
};

export const SendToBakeryModal = ({
  onComplete,
  value,
  onChange,
  ...props
}: SendToBakeryModalProps) => {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);

  const onFocus = useCallback(() => {
    if (!modalRef.current) return;
    modalRef.current.style.top = `0px`;
  }, []);

  const onBlur = useCallback(() => {
    if (!modalRef.current) return;
    modalRef.current.style.top = `auto`;
  }, []);

  const handleComplete = async () => {
    setLoading(true);
    onComplete();
    setLoading(false);
  };

  return (
    <>
      <UIModal {...props} ref={modalRef} detent="content-height">
        <div className={styles.root}>
          <UITypography variant="H1">{`Введите количество ${TOKEN_SHORTCUT}`}</UITypography>
          <UINumberInput
            value={value}
            onChange={onChange}
            variant="secondary"
            onFocus={onFocus}
            onBlur={onBlur}
            className={styles.input}
          />
          <UIButton onClick={handleComplete} fullwidth loading={loading}>
            {t('common.send')}
          </UIButton>
        </div>
      </UIModal>
    </>
  );
};
