import styles from './style.module.scss';
import { UIButton, UITypography } from 'shared/ui';
import variables from 'shared/ui/theme/variables.scss';
import { TickCircle } from 'assets/icons/tick-circle';
import { useGlobalStore, useReferralsStore } from 'shared/store';
import { formatLargeNumber } from 'shared/utils';
import { useTranslation } from 'react-i18next';
import { GAME_COIN_SHORTCUT, UNLOCK_BAKERY_COST } from 'shared/constants';
import { unlockBakery } from 'shared/api';
import { useState } from 'react';

export const BakeryBlocked = () => {
  const { t } = useTranslation();
  const referrals = useReferralsStore((state) => state.referrals);
  const user = useGlobalStore((state) => state.user);
  const updateUserStore = useGlobalStore((state) => state.updateUser);
  const reduceUserBalance = useGlobalStore((state) => state.reduceUserBalance);
  const [loading, setLoading] = useState(false);

  const currentRefs = referrals.length > 0 ? 1 : 0;
  const currentBalance = user.coins >= UNLOCK_BAKERY_COST ? UNLOCK_BAKERY_COST : user.coins;

  const handleUnlockBakery = async () => {
    try {
      setLoading(true);
      const { has_bakery_access } = await unlockBakery();
      updateUserStore({ has_bakery_access });
      reduceUserBalance(UNLOCK_BAKERY_COST);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <img src="/key.png" alt="" width={80} height={80} style={{ display: 'block' }} />
        <UITypography variant="H1" component="h1">
          {t('bakery.bakery_blocked')}
        </UITypography>
        <UITypography component="p" style={{ marginBottom: 8 }} preline>
          {`${t('bakery.bakery_blocked_text')} \n ${formatLargeNumber(UNLOCK_BAKERY_COST)} ${GAME_COIN_SHORTCUT}!`}
        </UITypography>

        <div className={styles.requirements}>
          <UITypography
            fontWeight="bold"
            iconLeft={
              <TickCircle
                bgColor={currentRefs > 0 ? variables.colorLightGreen : variables.colorWhite}
                opacity={currentRefs > 0 ? 1 : 0.4}
                tickColor={currentRefs > 0 ? variables.colorBlack : variables.colorWhite}
              />
            }
          >
            {`${t('friends.invited_friends')} ${currentRefs}/1`}
          </UITypography>
          <UITypography
            fontWeight="bold"
            style={{ marginBottom: 8 }}
            iconLeft={
              <TickCircle
                bgColor={
                  user.coins >= UNLOCK_BAKERY_COST
                    ? variables.colorLightGreen
                    : variables.colorWhite
                }
                opacity={user.coins >= UNLOCK_BAKERY_COST ? 1 : 0.4}
                tickColor={
                  user.coins >= UNLOCK_BAKERY_COST ? variables.colorBlack : variables.colorWhite
                }
              />
            }
          >
            {`${formatLargeNumber(currentBalance)}/${formatLargeNumber(UNLOCK_BAKERY_COST)} ${GAME_COIN_SHORTCUT}`}
          </UITypography>
        </div>
        <UIButton
          disabled={user.coins < UNLOCK_BAKERY_COST || referrals.length < 1}
          loading={loading}
          fullwidth
          onClick={handleUnlockBakery}
        >{`${t('bakery.unlock_for')} ${formatLargeNumber(UNLOCK_BAKERY_COST)} ${GAME_COIN_SHORTCUT}`}</UIButton>
      </div>
    </div>
  );
};
