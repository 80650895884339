import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { UIButton, UIModal, UITypography } from 'shared/ui';
import { useGlobalStore } from 'shared/store';
import { useState } from 'react';
import { getUser } from 'shared/api';
import walletImage from 'assets/images/wallet-front-color.png';

type ConnectWalletModalProps = {
  visible: boolean;
  onClose: () => void;
};

export const ConnectWalletModal = ({ visible, onClose }: ConnectWalletModalProps) => {
  const { t } = useTranslation();
  const updateUser = useGlobalStore((state) => state.updateUser);
  const [isLoading, setIsLoading] = useState(false);

  const updateUserWallet = async () => {
    try {
      setIsLoading(true);
      const newUser = await getUser();
      if (!newUser.data.wallet) return;

      updateUser({ wallet: newUser.data.wallet });
      onClose();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <UIModal visible={visible} onClose={onClose} detent="content-height">
      <div className={styles.container}>
        <img src={walletImage} alt="" width={80} height={80} style={{ flexShrink: 0 }} />
        <UITypography variant="H1" component="p">
          {t('settings.connect_wallet_modal.connect_wallet')}
        </UITypography>

        <UITypography component="p" className={styles.description}>
          {t('settings.connect_wallet_modal.non_android_text')}
        </UITypography>

        <UIButton loading={isLoading} onClick={updateUserWallet} fullwidth className={styles.btn}>
          {t('common.check')}
        </UIButton>
      </div>
    </UIModal>
  );
};
