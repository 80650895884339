import { UITypography } from 'shared/ui';
import styles from './style.module.scss';
import { useGameStore } from 'shared/store';

export const CoinsCounter = () => {
  const currCoins = useGameStore((state) => state.currCoins);

  return (
    <div className={styles.counter}>
      <img
        style={{ pointerEvents: 'none' }}
        src={'/donuts/don-coin.webp'}
        alt=""
        width={40}
        height={40}
      />
      <UITypography variant="Award">{currCoins}</UITypography>
    </div>
  );
};
