export const ONBOARDING_STEPS = [
  {
    step: 1,
    img: '/onboarding/1-main.webp',
    title: 'first_step_title',
    description: 'first_step_description',
    screenshots: [],
  },
  {
    step: 2,
    img: '/onboarding/2-main.webp',
    title: 'second_step_title',
    description: 'second_step_description',
    screenshots: ['/onboarding/2.1.webp', '/onboarding/2.2.webp', '/onboarding/2.3.webp'],
  },
  {
    step: 3,
    img: '/onboarding/3-main.webp',
    title: 'third_step_title',
    description: 'third_step_description',
    screenshots: ['/onboarding/3.1.webp', '/onboarding/3.2.webp', '/onboarding/3.3.webp'],
  },
  {
    step: 4,
    img: '/onboarding/4-main.webp',
    title: 'fourth_step_title',
    description: 'fourth_step_description',
    screenshots: ['/onboarding/4.1.webp', '/onboarding/4.2.webp', '/onboarding/4.3.webp'],
  },
  {
    step: 5,
    img: '/onboarding/5-main.webp',
    title: 'fifth_step_title',
    description: 'fifth_step_description',
    screenshots: ['/onboarding/5.1.webp', '/onboarding/5.2.webp', '/onboarding/5.3.webp'],
  },
  {
    step: 6,
    img: '/onboarding/6-main.webp',
    title: 'sixth_step_title',
    description: 'sixth_step_description',
    screenshots: ['/onboarding/6.1.webp', '/onboarding/6.2.webp', '/onboarding/6.3.webp'],
  },
  {
    step: 7,
    img: '/onboarding/7-main.webp',
    title: 'seventh_step_title',
    description: 'seventh_step_description',
    screenshots: ['/onboarding/7.1.webp', '/onboarding/7.2.webp', '/onboarding/7.3.webp'],
  },
];
