import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { OpenMobile } from 'components';

type PlatformProviderProps = {
  children: React.ReactNode;
};

export const PlatformProvider = ({ children }: PlatformProviderProps) => {
  const WebApp = useWebApp();

  const blockApp = WebApp.platform !== 'ios' && WebApp.platform !== 'android';

  return blockApp ? <OpenMobile /> : children;
};
