import { Sheet } from 'react-modal-sheet';
import styles from './style.module.scss';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import { forwardRef } from 'react';

export type UIModalProps = {
  visible: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  snapPoints?: number[];
  detent?: 'full-height' | 'content-height';
};

export const UIModal = forwardRef<HTMLDivElement, UIModalProps>(
  ({ visible, onClose, children, detent, snapPoints }, ref) => {
    return (
      <Sheet
        isOpen={visible}
        onClose={onClose}
        className={styles.root}
        snapPoints={snapPoints}
        detent={detent}
      >
        <Sheet.Container className={styles.container} ref={ref}>
          <Sheet.Scroller>
            <Sheet.Header className={styles.header}>
              <div onClick={onClose}>
                <CloseIcon />
              </div>
            </Sheet.Header>

            <Sheet.Content className={styles.content}>{children}</Sheet.Content>
          </Sheet.Scroller>
        </Sheet.Container>
        <Sheet.Backdrop onTap={onClose} />
      </Sheet>
    );
  },
);
