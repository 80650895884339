import { createWithEqualityFn } from 'zustand/traditional';
import { GetSettingsResponse } from 'shared/types';
import { deepShallow } from 'shared/utils';

type GameSettings = Omit<GetSettingsResponse, 'daily_donut_limit'>;

type GameState = {
  currCoins: number;
  gameFinished: boolean;
  frozen: boolean;
  gameReady: boolean;
  settings: GameSettings;
  sessionId: string;
  catchedBees: number;
  failedDonuts: number;
  incDonSpeedInterval: number;
  currDonutSpeed: number;
  donutsCleared: boolean;
};

type GameActions = {
  addCurrCoins: (count: number) => void;
  setGameFinished: (gameFinished: boolean) => void;
  setCurrCoins: (currCoins: number) => void;
  setFrozen: (frosen: boolean) => void;
  setGameReady: (gameReady: boolean) => void;
  setSettings: (newSettings: Partial<GameSettings>) => void;
  setSessionId: (sessionId: string) => void;
  increaseCatchedBees: (value: number) => void;
  increaseFailedDonuts: (value: number) => void;
  setCurrDonutSpeed: (value: number) => void;
  incDonutSpeed: (multiplier: number) => void;
  setDonutsCleared: (value: boolean) => void;
  resetGameState: () => void;
};

type GameSessionStore = GameState & GameActions;

const initialState: GameState = {
  currCoins: 0,
  gameFinished: false,
  frozen: false,
  gameReady: false,
  catchedBees: 0,
  failedDonuts: 0,
  sessionId: '',
  incDonSpeedInterval: 10, //seconds
  currDonutSpeed: 3,
  donutsCleared: false,
  settings: {
    conversion_rate: 0,
    cooldown_duration: 2, //seconds
    created_at: '',
    fall_bee_interval: 0.9, //seconds
    fall_bee_speed: 3, //seconds
    fall_donuts_interval: 1.1, //seconds
    fall_donuts_speed: 3, //seconds
    session_duration: 60, //seconds
    updated_at: '',
    uuid: '',
    waiting_time: 180, //seconds
    daily_bonus_limit: 1000,
    min_conversion_amount: 10000,
    max_count_ads: 0,
  },
};

export const useGameStore = createWithEqualityFn<GameSessionStore>(
  (set) => ({
    ...initialState,
    addCurrCoins: (count) =>
      set(({ currCoins }) => ({
        currCoins: currCoins + count,
      })),
    setGameFinished: (gameFinished) => set(() => ({ gameFinished })),
    setCurrCoins: (currCoins) => set(() => ({ currCoins })),
    setFrozen: (frozen) => set(() => ({ frozen })),
    setGameReady: (gameReady) => set(() => ({ gameReady })),
    setSettings: (newSettings: Partial<GameSettings>) =>
      set((state) => ({ settings: { ...state.settings, ...newSettings } })),
    setSessionId: (sessionId: string) => set(() => ({ sessionId })),
    increaseCatchedBees: (value: number) =>
      set((state) => ({ catchedBees: state.catchedBees + value })),
    increaseFailedDonuts: (value: number) =>
      set((state) => ({ failedDonuts: state.failedDonuts + value })),
    setCurrDonutSpeed: (currDonutSpeed: number) => set(() => ({ currDonutSpeed })),
    incDonutSpeed: (multiplier) =>
      set(({ currDonutSpeed }) => ({
        currDonutSpeed: currDonutSpeed - multiplier * currDonutSpeed,
      })),
    setDonutsCleared: (donutsCleared) => set(() => ({ donutsCleared })),
    resetGameState: () =>
      set((state) => {
        const {
          catchedBees,
          sessionId,
          gameFinished,
          frozen,
          donutsCleared,
          failedDonuts,
          gameReady,
          currCoins,
        } = initialState;

        return {
          catchedBees,
          sessionId,
          gameFinished,
          frozen,
          donutsCleared,
          failedDonuts,
          gameReady,
          currDonutSpeed: state.settings.fall_donuts_speed,
          currCoins,
        };
      }),
  }),
  deepShallow,
);
