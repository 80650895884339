import { INVITE_LINKS, TOKEN_SHORTCUT } from 'shared/constants';
import { useGlobalStore } from 'shared/store';

export const useInviteLink = () => {
  const user = useGlobalStore((state) => state.user);
  const botInfoLang = useGlobalStore((state) => state.botInfo.lang);

  return `${INVITE_LINKS[botInfoLang]}${user.tg_user_id}
Join the BOBE game, earn ${TOKEN_SHORTCUT} and convert it to crypto!`;
};
