import { m, Variants } from 'framer-motion';
import { CSSProperties, useMemo } from 'react';

type From = 'left' | 'right' | 'top' | 'bottom';
type Direction = 'horizontal' | 'vertical';

type SwipeProps = {
  children?: React.ReactNode;
  direction?: Direction;
  from?: From;
  style?: CSSProperties;
  variants?: Variants;
  delay?: number;
  initialTransparent?: boolean;
  start?: boolean;
};

const INITIAL_POSITION = {
  horizontal: {
    left: '-25px',
    right: '25px',
    top: '0',
    bottom: '0',
  },
  vertical: {
    top: '-25px',
    bottom: '25px',
    left: '0',
    right: '0',
  },
} as const;

export const Swipe = ({
  children,
  direction,
  from,
  style,
  variants,
  delay,
  initialTransparent = true,
  start,
}: SwipeProps) => {
  const initialX = direction === 'horizontal' ? INITIAL_POSITION.horizontal[from || 'top'] : '0';
  const initialY = direction === 'vertical' ? INITIAL_POSITION.vertical[from || 'left'] : '0';

  const animate = useMemo(() => {
    if (typeof start === 'boolean') {
      return start ? { opacity: 1, x: 0, y: 0 } : undefined;
    }

    return { opacity: 1, x: 0, y: 0 };
  }, [start]);

  return variants ? (
    <m.div variants={variants}> {children}</m.div>
  ) : (
    <m.div
      initial={{ x: initialX, y: initialY, opacity: initialTransparent ? 0 : 1 }}
      animate={animate}
      transition={{ type: 'spring', stiffness: 250, damping: 15, delay }}
      style={style}
    >
      {children}
    </m.div>
  );
};
