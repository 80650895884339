import { MutableRefObject } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { ROUTES } from 'shared/constants';

export const clearEntityQueue = <Entity extends { id: string }>(
  prev: Entity[],
  deleteQueue: MutableRefObject<string[]>,
) => {
  return prev.filter((entity) => {
    if (deleteQueue.current.includes(entity.id)) {
      deleteQueue.current = deleteQueue.current.filter((id) => id !== entity.id);
      return false;
    }
    return true;
  });
};

export const leaveSession = (nav: NavigateFunction, limitReached: boolean, coins: number) => {
  if (limitReached) {
    nav(ROUTES.LIMIT_REACHED, {
      replace: true,
      state: { coins: coins },
    });
  } else {
    nav(ROUTES.CONGRATULATIONS, {
      replace: true,
      state: { coins: coins },
    });
  }
};
