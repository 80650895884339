import styles from './style.module.scss';
import { memo, useEffect, useRef } from 'react';
import { FLY_SIZE, PRELOAD_GAME_ASSETS } from 'shared/constants';
import { useGameStore } from 'shared/store';
import { FlyEntity } from 'shared/types';

type FlyProps = FlyEntity & {
  onClick?: (id: string) => void;
  onAnimationComplete: (id: string) => void;
};

export const Fly = memo(
  ({
    startX,
    startY,
    onClick,
    id,
    endX,
    endY,
    rotation,
    onAnimationComplete,
    startDelay,
  }: FlyProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const animationDuration = useGameStore().settings.fall_bee_speed;

    const handleClick = () => {
      onClick?.(id);
      onAnimationComplete(id);

      if (!ref.current) return;
      ref.current.style.pointerEvents = 'none';
      ref.current.style.opacity = '0';
    };

    useEffect(() => {
      setTimeout(() => {
        if (!ref.current) return;
        ref.current.style.transform = `translate(${endX}px,${endY}px)`;
      });
    }, []);

    return (
      <div
        id={id}
        onTouchStart={handleClick}
        className={styles.root}
        style={{
          transform: `translate(${startX}px,${startY}px)`,
          transition: `transform ${animationDuration}s linear ${startDelay}s`,
        }}
        ref={ref}
      >
        <img
          src={PRELOAD_GAME_ASSETS.flyImage}
          alt=""
          className={styles['fly-image']}
          width={FLY_SIZE}
          height={FLY_SIZE}
          style={{ transform: `rotate(${rotation}deg)`, pointerEvents: 'none' }}
        />
      </div>
    );
  },
  (prev, next) => prev.id === next.id,
);
