import styles from './style.module.scss';
import { TOKEN_SHORTCUT } from 'shared/constants';
import { UIModal, UIModalProps, UITypography } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import threeBronzeCoins from 'assets/images/three-bronze-coins.png';

type SucessConvertModalProps = UIModalProps & {
  resultAmount: string;
};

export const SucessConvertModal = ({ resultAmount, ...other }: SucessConvertModalProps) => {
  const { t } = useTranslation();

  return (
    <UIModal detent="content-height" {...other}>
      <div className={styles.root}>
        <img src={threeBronzeCoins} alt="" width={264} height={170} style={{ display: 'block' }} />
        <UITypography variant="H1">
          {t('bakery.you_have_got')}
          <UITypography
            variant="H1"
            color="yellow"
            preline
          >{`\n ${resultAmount} ${TOKEN_SHORTCUT}`}</UITypography>
        </UITypography>
      </div>
    </UIModal>
  );
};
