import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { PlatformProvider } from 'shared/providers';
import { overrideWindowOpen } from 'shared/utils';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://439220eb2a9b0abae248625a94145abc@o4506116014866432.ingest.us.sentry.io/4508176644505600',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

window?.Telegram?.WebApp.expand();
window?.Telegram?.WebApp.disableVerticalSwipes();
window?.Telegram?.WebApp.ready();
overrideWindowOpen();

root.render(
  <BrowserRouter>
    <PlatformProvider>
      <App />
    </PlatformProvider>
  </BrowserRouter>,
);
