import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES, GAME_COIN_SHORTCUT } from 'shared/constants';
import { UITypography, UIButton } from 'shared/ui';
import { Scale, Swipe } from 'shared/ui/animations';
import { formatLargeNumber } from 'shared/utils';
import threeDonutsImage from 'assets/images/three-donuts.png';

export const LimitReachedScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const earnedCoins = location.state?.coins;

  const goToMain = () => {
    navigate(ROUTES.MAIN, { replace: true });
  };

  return (
    <div className={styles.root}>
      <Scale>
        <img src={threeDonutsImage} alt="" width={264} height={170} />
      </Scale>

      <Swipe direction="horizontal" from="left">
        <div className={styles.text}>
          <UITypography component="p" variant="H1">
            {t('common.you_have_reached_limit')}
          </UITypography>
          <UITypography
            variant="H1"
            component="p"
            color="yellow"
            className={styles.donutsCount}
          >{`+${formatLargeNumber(earnedCoins || 0)} ${GAME_COIN_SHORTCUT}`}</UITypography>
        </div>
      </Swipe>
      <Swipe direction="horizontal" from="right" style={{ width: '100%' }}>
        <UIButton fullwidth onClick={goToMain}>
          {t('congratulations_screen.back_to_main')}
        </UIButton>
      </Swipe>
    </div>
  );
};
