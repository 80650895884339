import { useCallback, useEffect, useRef } from 'react';
import { ShowPromiseResult, AdController } from 'shared/types/adsgram';

export interface useAdsgramParams {
  blockId: string;
  onReward: (result: ShowPromiseResult) => void;
  onError?: (result: ShowPromiseResult) => void;
}

export const useAdsgram = ({
  blockId,
  onReward,
  onError,
}: useAdsgramParams): (() => Promise<void>) => {
  const AdControllerRef = useRef<AdController | undefined>(undefined);

  useEffect(() => {
    if (blockId) {
      AdControllerRef.current = window.Adsgram?.init({
        blockId,
        debug: false,
        debugBannerType: 'FullscreenMedia',
      });
    } else {
      alert('No ads blockId');
      throw new Error('No ads blockId');
    }
  }, [blockId]);

  return useCallback(async () => {
    if (AdControllerRef.current) {
      AdControllerRef.current
        .show()
        .then((result) => {
          // user watch ad till the end
          onReward(result);
        })
        .catch((result: ShowPromiseResult) => {
          // user get error during playing ad or skip ad
          onError?.(result);
        });
    } else {
      onError?.({
        error: true,
        done: false,
        state: 'load',
        description: 'Adsgram script not loaded',
      });
    }
  }, [onError, onReward]);
};
