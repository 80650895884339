import styles from './style.module.scss';
import { useGlobalStore } from 'shared/store';
import { BakeryBlocked } from './bakery-blocked/bakery-blocked';
import { Conversation } from './conversation/conversation';
import { MainTokenBalance } from './main-token-balance/main-token-balance';
import { SendToBakery } from './send-to-bakery/send-to-bakery';
import { Swipe } from 'shared/ui/animations';

export const BakeryModule = () => {
  const user = useGlobalStore((state) => state.user);

  if (!user.has_bakery_access) {
    return (
      <div className={styles.root}>
        <Swipe from="top" direction="vertical" initialTransparent={false}>
          <BakeryBlocked />
        </Swipe>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <Swipe from="top" direction="vertical" initialTransparent={false}>
        <Conversation />
      </Swipe>
      <Swipe from="left" direction="horizontal">
        <MainTokenBalance />
      </Swipe>
      <Swipe from="right" direction="horizontal">
        <SendToBakery />
      </Swipe>
    </div>
  );
};
