type Params = {
  limitReached: boolean;
  dailyLimit: number;
  currentLimit: number;
  currCoins: number;
};

export const checkEarnedCoins = ({ limitReached, dailyLimit, currentLimit, currCoins }: Params) => {
  if (limitReached) {
    return dailyLimit - currentLimit > 0 ? dailyLimit - currentLimit : 0;
  } else {
    return currCoins;
  }
};
