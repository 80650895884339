import { LoadingProvider } from 'components';
import { Outlet } from 'react-router-dom';
import { useGlobalStore } from 'shared/store';

export const GlobalLoadingLayout = () => {
  const globalLoading = useGlobalStore((state) => state.globalLoading);

  return (
    <LoadingProvider loading={globalLoading} withBg>
      <Outlet />
    </LoadingProvider>
  );
};
