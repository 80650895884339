import { ENDPOINTS } from 'shared/constants';
import {
  AppUser,
  FinishSessionResponse,
  GetBotResponse,
  GetSettingsResponse,
  StartSessionResponse,
} from 'shared/types';
import { httpClientAuthorized } from '../httpClient';
import { AxiosRequestConfig } from 'axios';

export const startSession = async () => {
  return await httpClientAuthorized.post<StartSessionResponse>(ENDPOINTS.START_SESSION);
};

export const finishSession = async (
  session_id: string,
  loot: number,
  degradation: number,
  failed_loot: number,
) => {
  return await httpClientAuthorized.put<FinishSessionResponse>(
    ENDPOINTS.FINISH_SESSION(session_id),
    {
      loot,
      degradation,
      failed_loot,
    },
  );
};

export const getSettings = async () => {
  return await httpClientAuthorized.get<GetSettingsResponse>(ENDPOINTS.GET_SETTINGS);
};

export const getCurrentTimeUTC = async (props?: AxiosRequestConfig) => {
  try {
    const res = await httpClientAuthorized.get<{ now: string }>(ENDPOINTS.CURRENT_TIME, props);
    return { currentTimeUTC: res.data.now, currentTimestampUTC: new Date(res.data.now).getTime() };
  } catch (error) {
    console.log(error);
    return {
      currentTimeUTC: new Date().toUTCString(),
      currentTimestampUTC: new Date().getTime(),
    };
  }
};

export const updateBonusLimit = async (limit: number) => {
  const res = await httpClientAuthorized.post<AppUser>(ENDPOINTS.BONUS_LIMIT, { limit });
  return res.data;
};

export const fetchBotInfo = async () => {
  const res = await httpClientAuthorized.get<GetBotResponse>(ENDPOINTS.BOT);
  return res.data;
};
