import React, { FC } from 'react';

import styles from './slider.module.scss';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-icon.svg';
import { UIButton, UITypography } from '../../../shared/ui';
import { useTranslation } from 'react-i18next';
import { Swipe } from '../../../shared/ui/animations';
import classNames from 'classnames';
import { m } from 'framer-motion';

interface Props {
  img: string;
  title: string;
  description: string;
  onNext: () => void;
  onPrev: () => void;
  step: number;
  screenshots: string[];
  onSkip: () => void;
}

export const OnboardingSlider: FC<Props> = ({
  img,
  title,
  description,
  onPrev,
  onNext,
  step,
  screenshots,
  onSkip,
}) => {
  const { t } = useTranslation();

  const slideAnimation = {
    initial: { x: 300, opacity: 0 },
    animate: { x: 0, opacity: 1 },
    exit: { x: -300, opacity: 0 },
    transition: { duration: 0.5 },
  };

  const screenshotsAnimation = {
    initial: { y: 50, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    exit: { y: -50, opacity: 0 },
    transition: { duration: 0.5, delay: 0.2 },
  };

  return (
    <m.div
      className={classNames(styles.root, {
        [styles.secondRoot]: step !== 1,
      })}
      {...slideAnimation}
    >
      <UIButton variant="text" size="small2" onClick={onSkip} className={styles.skipBtn}>
        {t('common.skip')}
      </UIButton>

      <Swipe direction="horizontal" from="left">
        <div className={styles.sliderWrapper}>
          {step !== 1 && (
            <div className={styles.arrowContainer} onClick={onPrev}>
              <ArrowDownIcon className={styles.arrow} />
            </div>
          )}

          <m.img src={img} alt="img" {...slideAnimation} />

          <div className={styles.arrowContainer} onClick={onNext}>
            <ArrowDownIcon className={styles.arrow} />
          </div>
        </div>

        {step > 1 && (
          <UITypography className={styles.title} variant="H2">
            {t(`onboarding.${title}`)}
          </UITypography>
        )}

        <m.div className={styles.contentWrapper}>
          {step !== 1 && <img src="/onboarding/arrow.png" className={styles.arrow} alt="arrow" />}

          {step === 1 && (
            <UITypography className={styles.title} variant="H2">
              {t(`onboarding.${title}`)}
            </UITypography>
          )}

          <div className={styles.descriptionWrap}>
            {step !== 1 && <img src="/onboarding/bulb.png" alt="bulb" />}

            <UITypography className={styles.description} component="p">
              {t(`onboarding.${description}`)}
            </UITypography>
          </div>
        </m.div>

        {step !== 1 && (
          <m.div className={styles.screenshotsContainer} {...screenshotsAnimation}>
            {screenshots.map((screenshot, i) => (
              <img key={`${title}-${i}`} src={screenshot} alt={`${title}-${i}`} />
            ))}
          </m.div>
        )}
      </Swipe>
    </m.div>
  );
};
