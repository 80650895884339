import classNames from 'classnames';
import styles from './style.module.scss';
import { ReactComponent as VideoPlayIcon } from 'assets/icons/video-play.svg';
import { useAdsgram } from 'shared/hooks';
import { ShowPromiseResult } from 'shared/types/adsgram';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { UITypography } from 'shared/ui';
import { ROUTES } from 'shared/constants';
import { useGameStore, useGlobalStore } from 'shared/store';
import { updateBonusLimit } from 'shared/api';

type RewardType = 'daily_limit_increase' | 'start_session_instantly';

type ShowAdsButtonProps = {
  style?: React.CSSProperties;
  className?: string;
  rewardType: RewardType;
  textRight?: string | React.ReactNode;
  blockId: string;
};

export const ShowAdsButton = ({
  style,
  className,
  rewardType,
  textRight,
  blockId,
}: ShowAdsButtonProps) => {
  const nav = useNavigate();

  const dailyBonusLimit = useGameStore((state) => state.settings.daily_bonus_limit);
  const setGameReady = useGameStore((state) => state.setGameReady);
  const updateUser = useGlobalStore((state) => state.updateUser);

  const onReward = useCallback(async (result: ShowPromiseResult) => {
    try {
      if (!result.done) {
        alert("Ad hasn't done");
        return;
      }

      switch (rewardType) {
        case 'start_session_instantly': {
          setGameReady(true);
          nav(ROUTES.SESSION, { replace: true });
          break;
        }

        case 'daily_limit_increase': {
          const newUser = await updateBonusLimit(dailyBonusLimit);
          updateUser({
            daily_bonus_limit: newUser.daily_bonus_limit,
            current_count_ads: newUser.current_count_ads,
          });
          break;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const onError = useCallback((result: ShowPromiseResult) => {
    console.log(result);
  }, []);

  const showAds = useAdsgram({ onReward, onError, blockId });

  return (
    <div className={classNames(styles.root, className)} style={style} onClick={showAds}>
      <VideoPlayIcon />
      {typeof textRight !== 'string' ? (
        textRight
      ) : (
        <UITypography color="white" fontWeight="bold">
          {textRight}
        </UITypography>
      )}
    </div>
  );
};
