import styles from './style.module.scss';

type SpinnerProps = {
  size?: number;
  borderWidth?: number;
  color?: 'inherit' | string;
};

export const Spinner = ({ size = 50, borderWidth = 7, color }: SpinnerProps) => {
  return (
    <div
      className={styles.spinner}
      style={{
        width: size,
        height: size,
        borderWidth,
        borderColor: color,
        borderBottomColor: 'transparent',
      }}
    />
  );
};
