import { AchievementEntity, TaskEntity } from './../types/common';
import { create } from 'zustand';

type ProgressStore = {
  tasks: TaskEntity[];
  staticAchievements: AchievementEntity[];
  dynamicAchievements: AchievementEntity[];
  currentTask: TaskEntity | null;
  setTasks: (tasks: TaskEntity[]) => void;
  setStaticAchievements: (achievements: AchievementEntity[]) => void;
  setDynamicAchievements: (achievements: AchievementEntity[]) => void;
  updateTask: (task: TaskEntity) => void;
  setCurrentTask: (task: TaskEntity | null) => void;
};

export const useProgressStore = create<ProgressStore>((set) => ({
  tasks: [],
  staticAchievements: [],
  dynamicAchievements: [],
  currentTask: null,
  setTasks: (tasks) => set({ tasks }),
  setStaticAchievements: (staticAchievements) => set({ staticAchievements }),
  setDynamicAchievements: (dynamicAchievements) => set({ dynamicAchievements }),
  updateTask: (task) =>
    set((state) => ({ tasks: state.tasks.map((t) => (t.id === task.id ? task : t)) })),
  setCurrentTask: (task) => set({ currentTask: task }),
}));
