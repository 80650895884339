import { UIModal, UIModalProps, UITypography } from 'shared/ui';
import threeBronzeCoins from 'assets/images/three-bronze-coins.png';
import styles from './style.module.scss';
import { TOKEN_SHORTCUT } from 'shared/constants';
import { useTranslation } from 'react-i18next';

type SuccessSendBakeryModalProps = UIModalProps & {
  amount: string;
};

export const SuccessSendBakeryModal = ({ amount, ...props }: SuccessSendBakeryModalProps) => {
  const { t } = useTranslation();

  return (
    <UIModal {...props} detent="content-height">
      <div className={styles.root}>
        <img src={threeBronzeCoins} alt="" width={264} height={170} />
        <UITypography variant="H1">
          {`${t('bakery.you_successfully_sent')} \n`}
          <UITypography variant="H1" color="yellow">{`${amount} ${TOKEN_SHORTCUT} `}</UITypography>
          {`${t('bakery.to_bakery')}`}
        </UITypography>
      </div>
    </UIModal>
  );
};
