import { httpClientAuthorized, httpClientUnAuthorized } from '../httpClient';
import { METHODS, ENDPOINTS } from 'shared/constants';
import {
  CreateUserBody,
  CreateUserResponse,
  GetTokenResponse,
  GetUserResponse,
  UpdateUserBody,
  UpdateUserResponse,
} from 'shared/types/api';

export const createUser = async (data: CreateUserBody) => {
  return await httpClientUnAuthorized<CreateUserResponse>({
    method: METHODS.POST,
    url: ENDPOINTS.CREATE_USER,
    data,
  });
};

export const updateUser = async (data: UpdateUserBody) => {
  return await httpClientAuthorized<UpdateUserResponse>({
    method: METHODS.PUT,
    url: ENDPOINTS.UPDATE_USER,
    data,
  });
};

export const getUser = async () => {
  return await httpClientAuthorized<GetUserResponse>({
    method: METHODS.GET,
    url: ENDPOINTS.GET_USER,
  });
};

export const getToken = async () => {
  const res = await httpClientAuthorized.get<GetTokenResponse>(ENDPOINTS.TOKEN);
  return res.data;
};
