import { m } from 'framer-motion';
import styles from './style.module.scss';
import { useGlobalStore } from 'shared/store';
import { UICollapse, UITypography } from 'shared/ui';
import { LEFT_SWIPE_CHILD_VARIANTS } from 'shared/constants';

export const FaqScreen = () => {
  const faq = useGlobalStore((state) => state.faq);

  return (
    <m.div
      className={styles.root}
      variants={LEFT_SWIPE_CHILD_VARIANTS}
      initial="hidden"
      animate="visible"
    >
      <UITypography variant="H1" component="h1">
        FAQ
      </UITypography>

      {faq.map(({ answer, question }, index) => (
        <UICollapse label={question} key={index}>
          {answer}
        </UICollapse>
      ))}
    </m.div>
  );
};
