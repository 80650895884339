import styles from './style.module.scss';
import { Fly, FreezeOverlay, Donut } from 'components';
import { useDonuts } from './hooks/use-donuts';
import { useFlies } from './hooks/use-flies';
import { useEffect, useRef } from 'react';

const DonutContainer = () => {
  const { donuts, handleDonutClick, onDonAnimationComplete } = useDonuts();

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleDonAnimationEnd = (e: any) => {
      if (e.target.id.startsWith('don-id')) {
        onDonAnimationComplete(e.target.id);
      }
    };

    containerRef.current?.addEventListener('animationend', handleDonAnimationEnd);

    return () => {
      containerRef.current?.removeEventListener('animationend', handleDonAnimationEnd);
    };
  }, []);

  return (
    <div className={styles['game-container']} ref={containerRef}>
      {donuts.map((donut) => (
        <Donut
          {...donut}
          key={donut.id}
          onClick={handleDonutClick}
          onAnimationComplete={onDonAnimationComplete}
        />
      ))}
    </div>
  );
};

const FliesContainer = () => {
  const { flies, freezeCooldown, onFlyClick, onFlyAnimationComplete } = useFlies();

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleFlyAnimationEnd = (e: any) => {
      onFlyAnimationComplete(e.target.id);
    };

    containerRef.current?.addEventListener('transitionend', handleFlyAnimationEnd);

    return () => {
      containerRef.current?.addEventListener('transitionend', handleFlyAnimationEnd);
    };
  }, []);

  return (
    <div className={styles['game-container']} ref={containerRef}>
      {flies.map((fly) => (
        <Fly
          {...fly}
          key={fly.id}
          onClick={onFlyClick}
          onAnimationComplete={onFlyAnimationComplete}
        />
      ))}

      <FreezeOverlay freezeCooldown={freezeCooldown} />
    </div>
  );
};

export const GameContainer = () => {
  return (
    <div className={styles.container}>
      <DonutContainer />
      <FliesContainer />
    </div>
  );
};
