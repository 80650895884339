import { UITypography } from 'shared/ui';
import styles from './style.module.scss';
import { AchievementEntity } from 'shared/types';
import { TickCircle } from 'assets/icons/tick-circle';
import { formatLargeNumber } from 'shared/utils';
import variables from 'shared/ui/theme/variables.scss';
import { memo, useMemo } from 'react';

type AchievementProps = AchievementEntity;

const isEqual = (prev: AchievementProps, next: AchievementProps) =>
  prev.title === next.title && prev.current_value === next.current_value;

export const Achievement = memo(
  ({ title, icon, description, current_value, value, type }: AchievementProps) => {
    const isStatic = type === 'static';
    const completed = current_value >= value;

    const renderValue = useMemo(() => {
      if (isStatic) return formatLargeNumber(current_value);

      return completed
        ? formatLargeNumber(current_value)
        : `${formatLargeNumber(current_value)}/${value}`;
    }, [current_value, value, isStatic]);

    return (
      <div className={styles.root}>
        <img src={icon} alt="" width={40} height={40} />
        <div className={styles.info}>
          <div className={styles.text}>
            <UITypography variant="H2" component="p">
              {title}
            </UITypography>
            <UITypography component="p">{description}</UITypography>
          </div>

          <div className={styles.valueContainer}>
            <UITypography
              iconLeft={
                !isStatic && (
                  <TickCircle
                    bgColor={completed ? variables.colorLightGreen : variables.colorWhite}
                    opacity={completed ? 1 : 0.4}
                    tickColor={completed ? variables.colorBlack : variables.colorWhite}
                  />
                )
              }
            >
              {renderValue}
            </UITypography>
          </div>
        </div>
      </div>
    );
  },
  isEqual,
);
