import { useCallback, useEffect, useRef, useState } from 'react';
import { FLY_SIZE, MAX_FREEZE_COOLDOWN } from 'shared/constants';
import { useGameStore, useGlobalStore } from 'shared/store';
import { FlyEntity } from 'shared/types';
import { v4 } from 'uuid';
import { clearEntityQueue } from '../utils';

export const useFlies = () => {
  const [flies, setFlies] = useState<FlyEntity[]>([]);

  const { gameFinished, settings, setFrozen, increaseCatchedBees } = useGameStore((state) => ({
    settings: state.settings,
    gameFinished: state.gameFinished,
    setFrozen: state.setFrozen,
    increaseCatchedBees: state.increaseCatchedBees,
  }));
  const appVisible = useGlobalStore((state) => state.appVisible);

  const [freezeCooldown, setFreezeCooldown] = useState(settings.cooldown_duration * 1000);
  const flyDeleteQueue = useRef<string[]>([]);

  const onFlyAnimationComplete = useCallback((id: string) => {
    flyDeleteQueue.current.push(id);
  }, []);

  const onFlyClick = useCallback(() => {
    setFrozen(true);
    setFreezeCooldown((prev) => (prev < MAX_FREEZE_COOLDOWN ? prev + 1000 : MAX_FREEZE_COOLDOWN));
    increaseCatchedBees(1);
  }, []);

  const createFly = useCallback((screenWidth: number, screenHeight: number, startDelay: number) => {
    const id = `fly-id${v4()}`;

    const startX = Math.random() < 0.5 ? -FLY_SIZE : screenWidth + FLY_SIZE;
    const startY = Math.random() * (screenHeight - FLY_SIZE);
    const endX = startX > 0 ? -FLY_SIZE : screenWidth + FLY_SIZE;
    const endY = Math.random() * (screenHeight - FLY_SIZE);
    const deltaX = endX - startX;
    const deltaY = endY - startY;
    const angleInRadians = Math.atan2(deltaY, deltaX);
    const rotation = angleInRadians * (180 / Math.PI) + 90;

    return { id, startX, startY, endX, endY, rotation, startDelay };
  }, []);

  useEffect(() => {
    const fliesToAddCount = Math.round(2000 / (settings.fall_bee_interval * 1000));

    const interval = setInterval(
      () => {
        if (gameFinished) {
          setFlies([]);
          clearInterval(interval);
          return;
        }
        if (!appVisible) {
          clearInterval(interval);
          setFlies([]);
          return;
        }

        setFlies((prev) => {
          const fliesQueue = Array.from({ length: fliesToAddCount }, (_, index) =>
            createFly(window.innerWidth, window.innerHeight, index * settings.fall_bee_interval),
          );

          if (flyDeleteQueue.current.length > 0) {
            const filteredPrev = clearEntityQueue(prev, flyDeleteQueue);
            return [...filteredPrev, ...fliesQueue];
          }

          return [...prev, ...fliesQueue];
        });
      },
      fliesToAddCount * settings.fall_bee_interval * 1000,
    );

    return () => clearInterval(interval);
  }, [appVisible, gameFinished, createFly]);

  return {
    flies,
    onFlyClick,
    freezeCooldown,
    onFlyAnimationComplete,
  };
};
