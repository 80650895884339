type Params = string[][] | Record<string, string> | string | URLSearchParams;

export const buildUrl = (url: string, params?: Params) => {
  if (!url) {
    alert('No url provided');
    return '';
  }

  const _url = !url.endsWith('/') ? `${url}/` : url;

  const queryString = new URLSearchParams(params).toString();
  return queryString ? `${_url}?${queryString}` : `${_url}`;
};
