import styles from './style.module.scss';
import { memo, useRef, useState } from 'react';
import { UITypography } from 'shared/ui';
import { DonutEntity } from 'shared/types';
import { getRandomBoolean } from 'shared/utils';
import { useHapticFeedback } from '@vkruglikov/react-telegram-web-app';
import { StarsDonut } from 'components/lottie';

export type DonutProps = DonutEntity & {
  onClick: (donut: DonutEntity) => { isCorrect: boolean | null; value: number };
  onAnimationComplete: (id: string) => void;
};

export const Donut = memo(
  ({ size, type, onClick, id, positionX, value, fallDuration, startDelay }: DonutProps) => {
    const [impactOccurred] = useHapticFeedback();

    const [award, setAward] = useState<null | number>(null);

    const donutRef = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLDivElement>(null);
    const awardRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const onDonutClick = () => {
      if (donutRef.current) donutRef.current.style.pointerEvents = 'none';

      const { isCorrect, value: _value } = onClick({
        id,
        size,
        positionX,
        type,
        value,
        fallDuration,
        startDelay,
      });

      if (isCorrect) {
        setAward(_value);
        if (imageRef.current) imageRef.current.style.opacity = '0';
        return;
      }

      impactOccurred('soft');
      const leftDirection = getRandomBoolean();

      if (imageRef.current) {
        imageRef.current.classList.add(leftDirection ? styles.rotateLeft : styles.rotateRight);
      }

      if (containerRef.current) {
        containerRef.current.classList.add(leftDirection ? styles.jumpedLeft : styles.jumpedRight);
      }
    };

    return (
      <div
        id={id}
        className={styles['donut-container']}
        ref={donutRef}
        onTouchStart={onDonutClick}
        style={{
          animationDelay: `${startDelay}s`,
          animationDuration: `${fallDuration}s`,
          left: positionX,
        }}
      >
        <div className={styles.donutOuterContainer} ref={containerRef}>
          <div id="donut-image" className={styles['donut-inner-container']} ref={imageRef}>
            <img src={`/donuts/${type}-donut-${size}.png`} alt={''} className={styles.donut} />

            <div className={styles.donutCenter} style={{ width: size, height: size }}></div>
            <div className={styles.stars}>
              <StarsDonut />
            </div>
          </div>

          {award && award > 0 && (
            <UITypography
              variant="Award"
              className={`${styles.award} `}
              id="award-element"
              ref={awardRef}
            >
              {`+${award}`}
            </UITypography>
          )}
        </div>
      </div>
    );
  },
  (prev, next) => prev.id === next.id,
);
