import { useEffect, useRef, useState } from 'react';
import { useGameStore, useGlobalStore } from 'shared/store';
import { getValuesForTimer } from 'shared/utils';

type useGameTimerProps = {
  startValue: number;
};

export const useGameTimer = ({ startValue }: useGameTimerProps) => {
  const [seconds, setSeconds] = useState(startValue);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const appVisible = useGlobalStore((state) => state.appVisible);
  const incDonSpeedInterval = useGameStore((state) => state.incDonSpeedInterval);
  const incDonutSpeed = useGameStore((state) => state.incDonutSpeed);
  const setGameFinished = useGameStore((state) => state.setGameFinished);

  useEffect(() => {
    const effect = () => {
      if (!appVisible) {
        intervalRef.current && clearInterval(intervalRef.current);
        return;
      }

      intervalRef.current = setInterval(() => {
        setSeconds((prev) => {
          if (prev - 1 <= 0) {
            setGameFinished(true);
            intervalRef.current && clearInterval(intervalRef.current);
            return 0;
          }

          if (prev - 1 !== startValue && (prev - 1) % incDonSpeedInterval === 0) {
            incDonutSpeed(0.07);
          }

          return prev - 1;
        });
      }, 1000);
    };
    effect();

    return () => {
      intervalRef.current && clearInterval(intervalRef.current);
    };
  }, [incDonSpeedInterval, incDonutSpeed, setGameFinished, startValue, appVisible]);

  return { gameTime: getValuesForTimer(seconds), seconds };
};
