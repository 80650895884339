import { useCallback, useEffect, useRef, useState } from 'react';
import {
  DONUT_TYPES,
  DONUT_SIZES,
  DEFAULT_FINGER_SLIDER_CONFIG,
  DONUT_AWARD_VALUES,
} from 'shared/constants';
import { getRandomBoolean, getRandomValueWithChance, randomNumberBetween } from 'shared/utils';
import { useGameStore, useGlobalStore } from 'shared/store';
import { DonutEntity, DonutSize } from 'shared/types';
import { v4 } from 'uuid';
import { clearEntityQueue } from '../utils';

export const useDonuts = () => {
  const [donuts, setDonuts] = useState<DonutEntity[]>([]);
  const { user, appVisible } = useGlobalStore((state) => ({
    user: state.user,
    appVisible: state.appVisible,
  }));
  const {
    addCurrCoins,
    currentDonutSpeed,
    gameFinished,
    increaseFailedDonuts,
    setDonutsCleared,
    setGameFinished,
    settings,
  } = useGameStore((state) => ({
    settings: state.settings,
    currentDonutSpeed: state.currDonutSpeed,
    gameFinished: state.gameFinished,
    setDonutsCleared: state.setDonutsCleared,
    setGameFinished: state.setGameFinished,
    increaseFailedDonuts: state.increaseFailedDonuts,
    addCurrCoins: state.addCurrCoins,
  }));

  const requiredMinDonuts = useRef(25);
  const userFingerSize = parseInt(user.finger_size || '') || DEFAULT_FINGER_SLIDER_CONFIG.min;
  const currentCoinsCache = useRef(0);
  const donutDeleteQueue = useRef<string[]>([]);
  const donutsToAddCount = Math.round(1000 / (settings.fall_donuts_interval * 1000));

  const onDonAnimationComplete = useCallback((id: string) => {
    donutDeleteQueue.current.push(id);
  }, []);

  const handleDonutClick = useCallback(
    (donut: DonutEntity) => {
      if (userFingerSize === donut.size) {
        currentCoinsCache.current += donut.value;

        if (
          currentCoinsCache.current + user.current_limit >=
          user.daily_limit + user.daily_bonus_limit
        ) {
          const diff =
            currentCoinsCache.current +
            user.current_limit -
            (user.daily_limit + user.daily_bonus_limit);

          const finalValue = donut.value - diff > 0 ? donut.value - diff : 0;

          addCurrCoins(finalValue);
          setGameFinished(true);
          return { isCorrect: true, value: finalValue };
        }

        addCurrCoins(donut.value);
        return { isCorrect: true, value: donut.value };
      }

      increaseFailedDonuts(1);
      return { isCorrect: false, value: 0 };
    },
    [userFingerSize],
  );

  const createDonut = useCallback(
    (currDonutSpeed: number, startDelay: number) => {
      const id = `don-id${v4()}`;
      const type = DONUT_TYPES[randomNumberBetween(0, DONUT_TYPES.length - 1)];
      const positionX = randomNumberBetween(0, window.innerWidth - 100);
      let size = DONUT_SIZES[randomNumberBetween(0, DONUT_SIZES.length - 1)];
      const value = getRandomValueWithChance(DONUT_AWARD_VALUES);

      if (getRandomBoolean() && getRandomBoolean() && requiredMinDonuts.current > 0) {
        size = userFingerSize as DonutSize;
        requiredMinDonuts.current -= 1;
      }

      return { id, type, positionX, size, value, fallDuration: currDonutSpeed, startDelay };
    },
    [userFingerSize],
  );

  useEffect(() => {
    const interval = setInterval(
      () => {
        if (gameFinished) {
          clearInterval(interval);
          setDonuts([]);
          setDonutsCleared(true);
          return;
        }
        if (!appVisible) {
          clearInterval(interval);
          setDonuts([]);
          return;
        }

        setDonuts((prev) => {
          const donutsQueue = Array.from({ length: donutsToAddCount }, (_, index) => {
            return createDonut(currentDonutSpeed, index * settings.fall_donuts_interval);
          });

          if (donutDeleteQueue.current.length > 0) {
            const filteredPrev = clearEntityQueue(prev, donutDeleteQueue);
            return [...filteredPrev, ...donutsQueue];
          }

          return [...prev, ...donutsQueue];
        });
      },
      donutsToAddCount * settings.fall_donuts_interval * 1000,
    );

    return () => clearInterval(interval);
  }, [currentDonutSpeed, createDonut, gameFinished, appVisible, donutsToAddCount]);

  return {
    donuts,
    handleDonutClick,
    onDonAnimationComplete,
  };
};
