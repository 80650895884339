import { UITypography } from 'shared/ui';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useInitData } from '@vkruglikov/react-telegram-web-app';
import { SUPPORTED_LANGUAGES } from 'shared/constants';

export const OpenMobile = () => {
  const [initDataUnsafe] = useInitData();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const changeLang = async () => {
      if (
        initDataUnsafe?.user?.language_code &&
        Object.values(SUPPORTED_LANGUAGES).includes(
          initDataUnsafe.user.language_code as SUPPORTED_LANGUAGES,
        )
      ) {
        await i18n.changeLanguage(initDataUnsafe.user.language_code);
      }
    };
    changeLang();
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.overlay} />
      <div className={styles.content}>
        <img src="/qr-code.png" alt="" width={160} height={160} />
        <UITypography variant="H1" component="h1">
          {t('open_mobile.title')}
        </UITypography>
        <UITypography component="p"> {t('open_mobile.text')}</UITypography>
      </div>
    </div>
  );
};
