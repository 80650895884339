import { useTranslation } from 'react-i18next';
import { ROUTES } from 'shared/constants/routes';
import { ReactComponent as CoinIcon } from 'assets/icons/coin.svg';
import { ReactComponent as CoinIconActive } from 'assets/icons/coin-active.svg';
import { ReactComponent as RankingIcon } from 'assets/icons/ranking.svg';
import { ReactComponent as RankingIconActive } from 'assets/icons/ranking-active.svg';
import { ReactComponent as FriendsIcon } from 'assets/icons/friends.svg';
import { ReactComponent as FriendsIconActive } from 'assets/icons/friends-active.svg';
import { ReactComponent as ChartIcon } from 'assets/icons/chart.svg';
import { ReactComponent as ChartIconActive } from 'assets/icons/chart-active.svg';
import { ReactComponent as ShopIcon } from 'assets/icons/shop.svg';
import { ReactComponent as ShopIconActive } from 'assets/icons/shop-active.svg';
import styles from 'components/navbar/style.module.scss';

export const useNavbarTabs = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      id: 1,
      text: t('navbar.bakery'),
      icon: <CoinIcon className={styles.icon} />,
      activeIcon: <CoinIconActive className={styles.icon} />,
      path: ROUTES.BAKERY,
    },

    {
      id: 2,
      text: t('navbar.friends'),
      icon: <FriendsIcon className={styles.icon} />,
      activeIcon: <FriendsIconActive className={styles.icon} />,
      path: ROUTES.FRIENDS,
    },
    {
      id: 3,
      text: t('navbar.main'),
      icon: <RankingIcon className={styles.icon} />,
      activeIcon: <RankingIconActive className={styles.icon} />,
      path: ROUTES.MAIN,
    },
    {
      id: 4,
      text: t('navbar.progress'),
      icon: <ChartIcon className={styles.icon} />,
      activeIcon: <ChartIconActive className={styles.icon} />,
      path: ROUTES.PROGRESS,
    },
    {
      id: 5,
      text: t('navbar.market'),
      icon: <ShopIcon className={styles.icon} />,
      activeIcon: <ShopIconActive className={styles.icon} />,
      path: ROUTES.MARKET,
    },
  ];

  return { tabs };
};
