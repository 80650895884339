import { UITypography } from 'shared/ui';
import styles from './style.module.scss';
import variables from 'shared/ui/theme/variables.scss';
import { useGameTimer } from 'modules/game-session-module/hooks/use-game-timer';
import { useGameStore } from 'shared/store';

export const GameTimer = () => {
  const settings = useGameStore((state) => state.settings);
  const gameTime = useGameTimer({ startValue: settings.session_duration });
  const danger = gameTime.seconds <= 10;

  return (
    <div
      className={styles.timer}
      style={{
        border: `2px solid ${danger ? variables.colorRose : 'transparent'}`,
        backgroundColor: danger ? variables.colorRose20 : variables.colorWhite20,
        color: danger ? variables.colorRose : variables.colorWhite,
      }}
    >
      <UITypography
        color={'inherit'}
        variant="Button1"
      >{`${gameTime.gameTime.minutes}:${gameTime.gameTime.secondsLeft}`}</UITypography>
    </div>
  );
};
