import { SendToBakeryModal, SuccessSendBakeryModal } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'shared/constants';
import { useGlobalStore } from 'shared/store';
import { UIButton } from 'shared/ui';

export const SendToBakery = () => {
  const { t } = useTranslation();
  const nav = useNavigate();
  const user = useGlobalStore((state) => state.user);
  const [openSuccessSendModal, setOpenSuccessSendModal] = useState(false);
  const [openSendToBakeryModal, setOpenSendToBakeryModal] = useState(false);
  const [successAmount, setSuccessAmount] = useState('');
  const [value, setValue] = useState('');

  const handleChange = (_value: string) => {
    setValue(_value);
  };

  const sendToBakery = async () => {
    const _value = +value.replaceAll(' ', '');
    try {
      if (_value > user.tokens) {
        alert(t('alerts.not_enough_balance'));
        return;
      }
      if (!_value || isNaN(_value) || _value < 1) {
        alert(t('alerts.incorrect_value'));
        return;
      }

      //const newUser = await transferToBakery(_value);
      // setUser(newUser)
      setSuccessAmount(value);
      setOpenSuccessSendModal(true);
      setOpenSendToBakeryModal(false);
      setValue('');
    } catch (error) {
      console.log(error);
    }
  };

  const handleStartSending = () => {
    if (!user.wallet) {
      nav(ROUTES.SETTINGS);
      return;
    }

    setOpenSendToBakeryModal(true);
  };

  return (
    <div>
      <UIButton
        fullwidth
        disabled
        // onClick={handleStartSending}
      >
        {/* {t('bakery.send_to_bakery')} */}
        {t('common.coming_soon')}
      </UIButton>

      <SendToBakeryModal
        value={value}
        onChange={handleChange}
        visible={openSendToBakeryModal}
        onClose={() => setOpenSendToBakeryModal(false)}
        onComplete={sendToBakery}
      />
      <SuccessSendBakeryModal
        visible={openSuccessSendModal}
        onClose={() => setOpenSuccessSendModal(false)}
        amount={successAmount}
      />
    </div>
  );
};
