import { LoadingContainer } from './loading-container';

type LoadingProviderProps = {
  loading: boolean;
  children: React.ReactNode;
  withBg?: boolean;
};

export const LoadingProvider = ({ loading, children, withBg }: LoadingProviderProps) => {
  return loading ? <LoadingContainer withBg={withBg} /> : children;
};
