import { useEffect, useState } from 'react';
import { REFS_LIST_LIMIT } from 'shared/constants';
import { getRefs } from '../queries/referrals';
import { GetRefsResponse } from 'shared/types';
import { useReferralsStore } from 'shared/store';

export const useFetchRefs = (offset: { current: number }) => {
  const setRefsStore = useReferralsStore((state) => state.setRefsStore);

  const [loading, setLoading] = useState(true);
  const [level, setLevel] = useState(1);
  const [refsData, setRefsData] = useState<GetRefsResponse>({ count: 0, data: [] });

  useEffect(() => {
    const fetchRefs = async () => {
      try {
        setLoading(true);

        const refsRes = await getRefs(REFS_LIST_LIMIT, 0, level);

        setRefsData({
          count: refsRes.data.count,
          data: refsRes.data.data,
        });
        setRefsStore({
          count: refsRes.data.count,
          data: refsRes.data.data,
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchRefs();
  }, []);

  const onChangeTab = async (value: number) => {
    offset.current = 0;
    setRefsData((v) => ({ count: v.count, data: [] }));
    setLevel(value);

    try {
      setLoading(true);
      const data = await getRefs(REFS_LIST_LIMIT, 0, value);

      setRefsData({
        count: data.data.count,
        data: data.data.data,
      });
    } finally {
      setLoading(false);
    }
  };

  return { loading, refsData, level, onChangeTab, setRefsData };
};
