import variables from 'shared/ui/theme/variables.scss';

type TickCircleProps = {
  tickColor?: string;
  bgColor?: string;
  opacity?: number;
};

export const TickCircle = ({
  bgColor = variables.colorLightGreen,
  tickColor = variables.colorBlack,
  opacity = 1,
}: TickCircleProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity={opacity}
        d="M8.00001 14.6666C11.6819 14.6666 14.6667 11.6819 14.6667 7.99998C14.6667 4.31808 11.6819 1.33331 8.00001 1.33331C4.31811 1.33331 1.33334 4.31808 1.33334 7.99998C1.33334 11.6819 4.31811 14.6666 8.00001 14.6666Z"
        fill={bgColor}
      />
      <path
        d="M7.05334 10.3866C6.92 10.3866 6.79334 10.3333 6.7 10.2399L4.81333 8.35328C4.62 8.15994 4.62 7.83994 4.81333 7.64661C5.00667 7.45328 5.32667 7.45328 5.52 7.64661L7.05334 9.17994L10.48 5.75328C10.6733 5.55994 10.9933 5.55994 11.1867 5.75328C11.38 5.94661 11.38 6.26661 11.1867 6.45994L7.40667 10.2399C7.31334 10.3333 7.18667 10.3866 7.05334 10.3866Z"
        fill={tickColor}
      />
    </svg>
  );
};
