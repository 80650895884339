import styles from './style.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNavbarTabs } from 'shared/hooks/use-navbar-tabs';
import variables from 'shared/ui/theme/variables.scss';
import { UITypography } from 'shared/ui';

type NavBarItemProps = {
  active: boolean;
  icon: JSX.Element;
  text: string;
  activeIcon: JSX.Element;
  onClick: () => void;
};

export const Navbar = () => {
  const { tabs } = useNavbarTabs();
  const location = useLocation();
  const nav = useNavigate();

  const onTabClick = (pathname: string) => {
    nav(pathname);
  };

  return (
    <div className={styles.navbar}>
      {tabs.map((tab) => (
        <NavBarItem
          key={tab.id}
          active={location.pathname === tab.path}
          icon={tab.icon}
          text={tab.text}
          activeIcon={tab.activeIcon}
          onClick={() => onTabClick(tab.path)}
        />
      ))}
    </div>
  );
};

const NavBarItem = ({ active, icon, text, activeIcon, onClick }: NavBarItemProps) => {
  return (
    <div
      onClick={onClick}
      className={styles['navbar-item']}
      style={{
        border: `1px solid ${!active ? variables.colorBlack : variables.colorYellow}`,
        boxShadow: active ? variables.yellowShadow : undefined,
      }}
    >
      <div
        className={styles['navbar-item-content']}
        style={{
          opacity: active ? 1 : 0.5,
        }}
      >
        {active ? activeIcon : icon}
        <UITypography variant="Menu">{text}</UITypography>
      </div>
    </div>
  );
};
