import { AxiosError } from 'axios';

export const handleErrorStatus = (
  error: unknown,
  statusToHandle: number,
  callbackForStatus: () => void,
  callback?: () => void,
) => {
  if (error instanceof AxiosError && error.response?.status === statusToHandle) {
    callbackForStatus();
  } else {
    callback?.();
  }
  console.log(error);
};
