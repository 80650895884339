import { ENDPOINTS } from 'shared/constants';
import { httpClientAuthorized } from '../httpClient';
import { AppUser } from 'shared/types';

export const unlockBakery = async () => {
  const res = await httpClientAuthorized.put<AppUser>(ENDPOINTS.UNLOCK_BAKERY);
  return res.data;
};

export const bakeryConvert = async (coins: number) => {
  const res = await httpClientAuthorized.put<AppUser>(ENDPOINTS.BAKERY_CONVERT, { coins });
  return res.data;
};
