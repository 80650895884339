import { useRef, useState } from 'react';
import styles from './style.module.scss';
import { UIPortal } from '../portal/portal';
import { MAX_SELECT_WIDTH, SELECT_MENU_GAP } from 'shared/constants';
import { m } from 'framer-motion';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-icon.svg';
import { MenuList } from './menu-list';
import { SELECT_ARROW_VARIANTS } from 'shared/constants/animation-variants';
import classNames from 'classnames';

export type SelectOption = { label: string; value: string };

type SelectProps = {
  onChange: (selectedOption: SelectOption) => void;
  options: SelectOption[];
  portalId?: string;
  portal?: boolean;
  placeholder?: string;
  className?: string;
  defaultValue?: SelectOption;
};

export const UISelect = ({
  onChange,
  options = [],
  portal = true,
  portalId,
  placeholder,
  className,
  defaultValue,
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<SelectOption | undefined>(defaultValue);
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownTop = containerRef.current?.getBoundingClientRect().bottom
    ? containerRef.current.getBoundingClientRect().bottom + SELECT_MENU_GAP
    : 0;
  const dropdownLeft = containerRef.current?.getBoundingClientRect().left || 0;
  const dropdownRight = containerRef.current?.getBoundingClientRect().right || MAX_SELECT_WIDTH;
  const dropdownWidth = containerRef.current?.getBoundingClientRect().width || MAX_SELECT_WIDTH;
  const mergedContainerClassName = classNames(styles['dropdown'], className);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleItemClick = (item: SelectOption) => {
    setSelectedItem(item);
    setIsOpen(false);
    onChange(item);
  };

  return (
    <div className={mergedContainerClassName} ref={containerRef}>
      <m.div
        animate={isOpen ? 'up' : 'down'}
        initial="down"
        exit="down"
        variants={SELECT_ARROW_VARIANTS}
        className={styles['dropdown-arrow']}
      >
        <ArrowDownIcon className={styles.arrow} />
      </m.div>
      <input
        type="text"
        value={selectedItem?.label || ''}
        onClick={toggleDropdown}
        readOnly
        placeholder={placeholder}
        className={styles.input}
      />
      {isOpen &&
        (portal ? (
          <UIPortal wrapperId={portalId || 'select-dropdown-wrapper'}>
            <MenuList
              dropdownLeft={dropdownLeft}
              dropdownRight={dropdownRight}
              dropdownTop={dropdownTop}
              dropdownWidth={dropdownWidth}
              options={options}
              handleItemClick={handleItemClick}
              isOpen={isOpen}
            />
            <div className={styles.overlay} onClick={() => setIsOpen(false)} />
          </UIPortal>
        ) : (
          <>
            <MenuList
              dropdownLeft={dropdownLeft}
              dropdownRight={dropdownRight}
              dropdownTop={dropdownTop}
              dropdownWidth={dropdownWidth}
              options={options}
              handleItemClick={handleItemClick}
              isOpen={isOpen}
            />
            <div className={styles.overlay} onClick={() => setIsOpen(false)} />
          </>
        ))}
    </div>
  );
};
