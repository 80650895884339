import axios, { CreateAxiosDefaults } from 'axios';
import { sha256 } from 'js-sha256';
import { appSessionStorage } from 'shared/service';

const SECRET = process.env.REACT_APP_SECRET_KEY;

const defaultHttpClientConfig: CreateAxiosDefaults = {
  baseURL: process.env.REACT_APP_BOBE_API,
  headers: {
    'Content-Type': 'application/json',
    'x-request': SECRET ? sha256.hmac(SECRET, new Date().toUTCString()) : '',
  },
};

export const httpClientAuthorized = axios.create(defaultHttpClientConfig);
export const httpClientUnAuthorized = axios.create(defaultHttpClientConfig);

httpClientAuthorized.interceptors.request.use(
  (config) => {
    const accessToken = appSessionStorage.getToken();
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;

      if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_X_BOT_TOKEN) {
        config.headers['X-Bot-Token'] = process.env.REACT_APP_X_BOT_TOKEN;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
